<template>
  <xlsxUpload
    :options="options"
    :sheet-options="sheetOptions"
    @on-select-file="onselectfile"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-for="(val, key) in $scopedSlots" v-slot:[key]="scope">
      <slot :name="key" v-bind="scope"></slot>
    </template>
  </xlsxUpload>
</template>

<script>
import Emitter from '@/mixins/form/emitter'
import xlsxUpload from '@/components/xlsxUpload'
import * as _ from 'lodash'
import { COLUMN_GROUP_TYPE_DIC } from '@/utils/constant/fieldConst'
import { validData } from '@/components/avue/utils/util'

export default {
  components: { xlsxUpload },
  mixins: [Emitter],
  data() {
    return {
      options: { opts: { header: 1 } }
    }
  },
  computed: {
    sheetOptions({ columnGroupTypeObj }) {
      return {
        'Data Definitions': {
          fields: ['Group Name', 'Field Name', 'Local Label Name', 'Required?']
        },
        'Template': {
          formatData({ xlsxArr }) {
            if (xlsxArr.slice(3).some(row => row.length)) return '请上传空白模板（Template中不应存在数据）'

            const groupColumn = xlsxArr[0]
            let columnGroupType = columnGroupTypeObj.Required
            return xlsxArr[2].map((fieldName, index) => {
              columnGroupType = validData(columnGroupTypeObj[groupColumn[index]], columnGroupType)
              return {
                fieldName,
                columnGroupType
              }
            })
          }
        },
        'Valid Values': {
          formatData({ xlsxArr }) {
            // console.log('Valid Values',{xlsxArr})
            let tempArr = []
            let columnGroupType
            for (const row of xlsxArr) {
              if (row[0] === undefined) {
                row[0] = columnGroupType
                tempArr.push(row)
              } else {
                columnGroupType = columnGroupTypeObj[row[0]]
              }
            }
            return tempArr
          }
        }
      }
    },
    columnGroupTypeObj() {
      return COLUMN_GROUP_TYPE_DIC.reduce((prev, next) => {
        prev[next.label] = next.value
        return prev
      }, {})
    }
  },
  methods: {
    onselectfile(files) {
      if (typeof files['Template'] === 'string') return this.$message.warning(files['Template'])

      // console.log(files)
      try {
        const oFieldObj = {}
        const fieldObj = {}
        let columnGroupName
        files['Data Definitions'].body.forEach((row) => {
          const groupName = row['Group Name']
          if (groupName) columnGroupName = groupName.split(' - ')[0] || columnGroupName
          row.columnGroupName = columnGroupName

          let fieldName = row['Field Name']
          if (!fieldName) return
          fieldObj[fieldName] = row
          oFieldObj[fieldName] = row

          // Data Definitions中存在多个字段合成一个字段，有如下两种：
          // 1：pesticide_marking_type1 - pesticide_marking_type3（pesticide_marking_type1；pesticide_marking_type2；pesticide_marking_type3）
          // 2：california_proposition_65_chemical_names1（california_proposition_65_chemical_names1；california_proposition_65_chemical_names2...）（这种不确定字段个数）
          const fieldNames = fieldName.split(' - ')
          if (fieldNames.length > 1) fieldName = fieldNames[0]
          // 去掉尾部数字
          fieldObj[fieldName.replace(/\d+$/, '')] = row
        })
        // console.log(oFieldObj)
        // console.log(fieldObj)

        const { columnGroupTypeObj } = this
        const columnArr = []
        const fields = []
        files['Template'].forEach(({ fieldName }) => {
          // 字段是否存在取决于 Data Definitions（即字段是否存在于Data Definitions中）
          // 解决Data Definitions中字段名bullet_point1 - bullet_point5
          const field = fieldObj[fieldName] || fieldObj[fieldName.replace(/\d+$/, '')]
          if (!field) return

          field && columnArr.push([fieldName, field['Local Label Name'], columnGroupTypeObj[field.columnGroupName]])
          fields.push({
            ...field,
            columnGroupType: columnGroupTypeObj[field.columnGroupName],
            'Field Name': fieldName
          })
        })
        // console.log(fields)
        // console.log(columnArr)
        const mainArray = this.getFieldValidValues(columnArr, files['Valid Values'])
        // console.log(mainArray)
        // console.log(mainArray.reduce((prev, next) => Object.assign(prev, next), {}))

        const value = { files, fields, mainArray, productType: _.find(mainArray, 'feed_product_type')['feed_product_type'][0] }
        this.$emit('input', value)
        this.handleChange(value)
        // console.log(value)
      } catch (e) {
        // console.log(e)
        this.$message.warning('请检查上传文件是否正确')
      }
    },
    getFieldValidValues(columnArr, oldArr) {
      /*var columnArr=[
       ["product_type","Product Type"],
       ["brand_name","Brand Name"],
       // 这里模拟顺序不一一对应
       ["relationship_type","Relationship Type"],
       ["parentage","Parentage"],
       ];
       var oldArr=[
       // 第一行数据固定在第一行
       [null,"Product Type","vehicleseatcover"],
       [null,"Brand Name","NDISTIN"],
       [null,"Parentage - [ vehicleseatcover ]","parent","child"],
       [null,"Relationship Type - [ vehicleseatcover ]","Accessory","Customized","Variation"]
       ];*/

      var replaceStr=oldArr[0][2];
      var pattern = new RegExp(`\\s*-\\s*\\[\\s*${replaceStr}\\s*\\]`)

      var mainArray=[];

      oldArr.forEach(itemArr=>{
        //var name=_.replace(_.head(_.tail(itemArr))," - [ "+replaceStr+" ]","");
        // 更安全替代方式,即使空格变动,也不会有影响
        // 有部分字段中间会有-符号（Shipping-Template）
        /*
         var tmpName=_.replace(_.head(_.tail(itemArr)),replaceStr,"");
         tmpName=_.replace(tmpName,"-","");
         tmpName=_.replace(tmpName,"[","");
         tmpName=_.replace(tmpName,"]","");
         tmpName=_.trimEnd(tmpName);
         var name=tmpName;
         */
        pattern.lastIndex = 0
        var name=_.head(_.tail(itemArr))
          .replace(pattern, "")
          // .replaceAll(replaceStr,"")
          // .replaceAll(" - ","")
          // .replaceAll("[","")
          // .replaceAll("]","")
          .trimEnd();

        var dbColumnNames=_.filter(columnArr, function(item) {
          if (item[2] && itemArr[0] && item[2] !== itemArr[0]) return
          return item[1]=== name
        });
        itemArr = _.tail(_.tail(itemArr));
        // console.log(_.tail(_.tail(itemArr)));
        dbColumnNames.forEach(dbColumnName => {
          if(dbColumnName!=null){
            var item={};
            item[dbColumnName[0]] = itemArr
            mainArray.push(item)
          }
        })
      });

      return _.uniqWith(mainArray, _.isEqual)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>